import { FC } from "react";
import { ReadMoreButton } from "@root/shared/components/ReadMoreButton";
import { Icon } from "@root/shared/components/Icon";
import { MultiLineText } from "@root/shared/components/MultiLineText";
import * as styles from "./styles.css";

type Props = {
  children: string;
};

export const PremiumScoutMessageBox: FC<Props> = ({ children }) => (
  <div className={styles.wrapper}>
    <div className={styles.titleWrapper}>
      <div className={styles.iconWrapper}>
        <Icon name="human" />
      </div>
      <p className={styles.titleText}>企業担当者からのメッセージ</p>
    </div>
    <div className={styles.readMoreButtonWrapper}>
      <ReadMoreButton size="small" containerMaxHeight={54}>
        <div className={styles.readMoreButtonInner}>
          <MultiLineText text={children} />
        </div>
      </ReadMoreButton>
    </div>
  </div>
);
