import { ComponentProps, FC, useEffect, useRef, useState } from "react";
import { Icon } from "@root/shared/components/Icon";
import { TextButton } from "@root/shared/components/TextButton";
import { InformationText } from "@root/shared/components/InformationText";
import { InformationCard } from "@userFrontend/components/InformationCard";
import { ReadMoreButton } from "@root/shared/components/ReadMoreButton";
import { SearchModal } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/SearchModal";
import { JobEntryRadioButton } from "@userFrontend/components/JobEntryRadioButton";
import { ErrorInformationText } from "@userFrontend/components/ErrorInformationText";
import { SiteId } from "@root/shared/types/type";
import { ErrorText } from "@userFrontend/features/reward/components/ContinuedRewardInputs/type";
import { Client } from "@userFrontend/features/clientReview/components/ClientReviewInputs/type";
import * as styles from "./styles.css";

type Props = {
  client?: Client;
  handleShowModal: () => void;
  checkedJobEntryId: number | null;
  jobEntryButtons: (Pick<
    ComponentProps<typeof JobEntryRadioButton>,
    "clientName" | "brandName" | "workTitle" | "jobEntryId"
  > & {
    siteId: SiteId;
  })[];
  clientErrorText: ErrorText;
} & ComponentProps<typeof SearchModal>;

export const EntryClientSelect: FC<Props> = ({
  client,
  checkedJobEntryId,
  jobEntryButtons,
  clientErrorText,
  isShowSearchModal,
  handleCloseModal,
  clientNameValue,
  shopNameValue,
  clientNameErrorText,
  shopNameErrorText,
  handleClientSearch,
  handleChangeClientNameValue,
  handleChangeShopNameValue,
  searchResults,
  handleClientSelect,
  isShowClientsDetail,
  handleShowTextButton,
  searchResultErrorText,
  displaySearchText,
  isSearching,
  handleReturnToMyPageTop,
  userContactPath,
  handleShowModal,
}) => {
  const [containerHeight, setContainerHeight] = useState(0);
  const jobEntryRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (
      jobEntryRefs.current[0] &&
      jobEntryRefs.current[1] &&
      jobEntryRefs.current[2] &&
      setContainerHeight
    ) {
      const totalRefHeight =
        jobEntryRefs.current[0].offsetHeight +
        jobEntryRefs.current[1].offsetHeight +
        jobEntryRefs.current[2].offsetHeight;
      setContainerHeight(totalRefHeight);
    }
  }, [jobEntryButtons, setContainerHeight]);

  return (
    <>
      <InformationCard>
        <p className={styles.text}>リジョブ経由で採用</p>
        <div className={styles.buttonWrapper}>
          <ReadMoreButton
            size="large"
            {...(containerHeight > 0 ? { containerMaxHeight: containerHeight } : {})}
          >
            <>
              {jobEntryButtons.map(
                ({ clientName, brandName, workTitle, jobEntryId, siteId }, index) => (
                  <div key={jobEntryId} ref={(el) => (jobEntryRefs.current[index] = el)}>
                    <JobEntryRadioButton
                      brandName={brandName}
                      clientName={clientName}
                      workTitle={workTitle}
                      jobEntryId={jobEntryId}
                      disabled={false}
                      isSp
                      checked={checkedJobEntryId === jobEntryId}
                      isError={!!clientErrorText}
                      handleChangeEntryClient={() =>
                        handleClientSelect(
                          siteId,
                          clientName,
                          {
                            key: "jobEntryId",
                            value: jobEntryId,
                          },
                          brandName,
                          workTitle,
                        )
                      }
                      name="jobEntry"
                    />
                  </div>
                ),
              )}
            </>
          </ReadMoreButton>
        </div>
      </InformationCard>
      <div>
        <div className={styles.box}>
          <div className={styles.boxInner}>
            <p>上記以外から選ぶ</p>
            <TextButton onClick={handleShowModal} size="small" rightIconName="arrow-light-right">
              投稿先を検索する
            </TextButton>
          </div>
          {client?.clientName && client?.clientId && (
            <p className={styles.clientNameValue}>企業名 {client.clientName}</p>
          )}
        </div>
        <SearchModal
          isShowSearchModal={isShowSearchModal}
          handleCloseModal={handleCloseModal}
          clientNameValue={clientNameValue}
          shopNameValue={shopNameValue}
          clientNameErrorText={clientNameErrorText}
          shopNameErrorText={shopNameErrorText}
          handleClientSearch={handleClientSearch}
          handleChangeClientNameValue={handleChangeClientNameValue}
          handleChangeShopNameValue={handleChangeShopNameValue}
          searchResults={searchResults}
          handleClientSelect={handleClientSelect}
          isShowClientsDetail={isShowClientsDetail}
          handleShowTextButton={handleShowTextButton}
          searchResultErrorText={searchResultErrorText}
          displaySearchText={displaySearchText}
          isSearching={isSearching}
          handleReturnToMyPageTop={handleReturnToMyPageTop}
          userContactPath={userContactPath}
        />
      </div>
      {clientErrorText && <ErrorInformationText text={clientErrorText} />}
      <InformationText
        icon={<Icon name="information" />}
        text={<>リジョブに求人を掲載中の企業のみ、投稿を受け付けています。</>}
        className={styles.informationText}
      />
    </>
  );
};
