import { FC } from "react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { Button } from "@root/shared/components/Button";
import { Link } from "@root/shared/components/Link";
import { AcceptScoutLink } from "@userFrontend/components/Links/AcceptScoutLink";
import { KeepForm } from "@userFrontend/features/publication/components/KeepForm";
import { EntryLink } from "@userFrontend/components/Links/EntryLink";
import { SalonTourButton } from "@userFrontend/components/Buttons/SalonTourButton";
import { useSalonTourButton } from "@userFrontend/hooks/useSalonTourButton";
import { ScoutDeadlineInformation } from "@userFrontend/features/publication/components/ScoutDeadlineInformation";
import { ExtendScoutDeadlineButton } from "@userFrontend/components/Buttons/ExtendScoutDeadlineButton";
import { ButtonProps } from "@userFrontend/features/publication/components/DetailPageActionButtons/type";
import { PopOver } from "@root/shared/components/PopOver";
import { LineConsultButton } from "@userFrontend/components/Buttons/LineConsultButton";
import * as styles from "./styles.css";

export const DefaultStyleButtons: FC<ButtonProps> = ({
  workCode,
  clientShopId,
  isApp,
  isPreview,
  entryProps,
  keepProps,
  salonTourProps,
  scoutProps,
  extendScoutProps,
  declineScoutProps,
  lineProps,
}) => {
  const { handleClickSalonTourButton } = useSalonTourButton();
  // 見学応募の見た目
  const salonTourButtonTheme = scoutProps.isScouted ? "secondary" : "tertiary";

  return (
    <div>
      {scoutProps.isScouted && (
        <>
          {/* truthyあるいは0の場合 */}
          {(extendScoutProps.scoutDeadline || extendScoutProps.scoutDeadline === 0) && (
            <span className={styles.deadlineWrapper}>
              <ScoutDeadlineInformation remainingDaysNum={extendScoutProps.scoutDeadline} />
            </span>
          )}
        </>
      )}
      <div
        className={styles.buttonWrapper}
        style={assignInlineVars({
          [styles.buttonMaxWidth]: scoutProps.isScouted ? "226px" : "460px",
        })}
      >
        {entryProps.hasEntried && (
          <>
            {lineProps?.isShowLineConsultButton && (
              <div className={styles.lineConsultButtonOuter({ hasMargin: false })}>
                <LineConsultButton onClick={lineProps.onClick} />
              </div>
            )}
            <div className={styles.hasEntriedButtonWrapper}>
              <Button theme="disabled">応募済み</Button>
            </div>
          </>
        )}
        {scoutProps.isScouted && salonTourProps.isSalonTour && (
          <div className={styles.salonTourButtonWrapper}>
            <SalonTourButton
              text="見学を希望する"
              buttonTheme={salonTourButtonTheme}
              onClick={() =>
                handleClickSalonTourButton(
                  isApp,
                  workCode,
                  scoutProps.isScouted,
                  clientShopId,
                  undefined,
                  undefined,
                  salonTourProps.url,
                )
              }
            />
          </div>
        )}
        {!entryProps.hasEntried && (
          <>
            <div className={styles.flexButtonWrapper}>
              {entryProps.isEnterable && !scoutProps.isScouted && (
                <>
                  <div className={styles.keepButtonWrapper}>
                    <KeepForm
                      isPreview={isPreview}
                      url={keepProps.url}
                      workCode={workCode}
                      hasKeeped={keepProps.hasKeeped}
                      onClick={keepProps.onClick}
                      gaAction={keepProps.gaAction}
                      gaLabel={keepProps.gaLabel}
                    />
                  </div>
                  {salonTourProps.isSalonTour && (
                    <div className={styles.salonTourButtonWrapper}>
                      <SalonTourButton
                        text="見学を希望する"
                        buttonTheme={salonTourButtonTheme}
                        onClick={() =>
                          handleClickSalonTourButton(
                            isApp,
                            workCode,
                            scoutProps.isScouted,
                            clientShopId,
                            undefined,
                            undefined,
                            salonTourProps.url,
                          )
                        }
                      />
                    </div>
                  )}
                  {lineProps?.isShowLineConsultButton && !salonTourProps.isSalonTour && (
                    <div className={styles.lineConsultButtonOuter({ hasMargin: false })}>
                      <LineConsultButton onClick={lineProps.onClick} />
                    </div>
                  )}
                  <div className={styles.entryButtonWrapper}>
                    {!isApp && (
                      <div className={styles.entryButtonPopoverContainer}>
                        <PopOver arrowPosition="bottomCenter">
                          <p className={styles.entryButtonPopOverSubText}>
                            書いていないこと・聞きたいことがあったら...
                          </p>
                          <p className={styles.entryButtonPopOverText}>質問メッセージも送れます</p>
                        </PopOver>
                      </div>
                    )}
                    <EntryLink
                      text="応募画面へ進む"
                      firebaseAction={entryProps.firebaseAction}
                      url={entryProps.url}
                      onClick={entryProps.onClick}
                      gaAction={entryProps.gaAction}
                      gaLabel={entryProps.gaLabel}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {scoutProps.isScouted && scoutProps.url && (
          <div className={styles.acceptButtonWrapper}>
            <AcceptScoutLink text="スカウトを受ける" url={scoutProps.url} workCode={workCode} />
          </div>
        )}
        {scoutProps.isScouted && (
          <>
            {extendScoutProps.isScoutExtend ? (
              <div className={styles.isScoutextendButtonWrapper}>
                <ExtendScoutDeadlineButton
                  gaAction={extendScoutProps.gaAction}
                  gaLabel={extendScoutProps.gaLabel}
                  onClick={extendScoutProps.onClick}
                >
                  <p>スカウト期限延長する</p>
                </ExtendScoutDeadlineButton>
              </div>
            ) : (
              <div className={styles.isScoutextendButtonWrapper}>
                <Button theme="disabled">スカウト期限延長済み</Button>
              </div>
            )}
            <div className={styles.declineButtonWrapper}>
              <Link
                theme="tertiary"
                href={declineScoutProps.url}
                className="js-scout-decline"
                data-ga-action={declineScoutProps.gaAction}
                data-ga-label={declineScoutProps.gaLabel}
              >
                辞退する
              </Link>
            </div>
          </>
        )}
      </div>
      {lineProps?.isShowLineConsultButton &&
        !entryProps.hasEntried &&
        !(!salonTourProps.isSalonTour && !scoutProps.isScouted) && (
          <div className={styles.lineConsultButtonOuter({ hasMargin: true })}>
            <LineConsultButton onClick={lineProps.onClick} />
          </div>
        )}
    </div>
  );
};
