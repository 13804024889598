import { FC } from "react";
import { RadioButton } from "@root/shared/components/RadioButton";
import { USER_REWARD_NAME } from "@root/utils/constants";
import * as styles from "./styles.css";

type Props = {
  clientName: string;
  brandName: string;
  workTitle: string;
  jobEntryId: number;
  disabled: boolean;
  checked: boolean;
  isSp: boolean;
  isError: boolean;
  userContactUrl?: string;
  handleChangeEntryClient: (jobEntryId: number) => void;
  name: string;
};

export const JobEntryRadioButton: FC<Props> = ({
  clientName,
  brandName,
  workTitle,
  jobEntryId,
  disabled,
  checked,
  isSp,
  isError,
  userContactUrl,
  handleChangeEntryClient,
  name,
}) => {
  const deviceType = isSp ? "sp" : "pc";
  const truncateTitle = (str: string) => {
    if (str.length <= 23) return str;
    return `${str.slice(0, 23 - 3)}...`;
  };

  return (
    <label className={styles.label[deviceType]} htmlFor={`job_entry-${jobEntryId}`}>
      <div className={styles.radioButtonBox[deviceType]}>
        <RadioButton
          name={name}
          value={jobEntryId}
          checked={checked}
          disabled={disabled}
          id={`job_entry-${jobEntryId}`}
          hasError={isError}
          onChange={() => handleChangeEntryClient(jobEntryId)}
        />
      </div>
      <div className={styles.jobEntryInfo[deviceType]}>
        <p className={styles.jobEntrySubInfo}>企業名：{clientName}</p>
        <p className={styles.jobEntrySubInfo}>ブランド名：{brandName}</p>
        <p className={styles.workTitle[deviceType]}>
          {isSp ? truncateTitle(workTitle) : workTitle}
        </p>
        {disabled && userContactUrl && (
          <p className={styles.disabledExplanation}>
            {`この求人の${USER_REWARD_NAME}については、`}
            <a href={userContactUrl}>こちら</a>からご連絡ください。
          </p>
        )}
      </div>
    </label>
  );
};
