import { Suspense, FC, lazy } from "react";
import { Props } from "./type";

const Presentation = lazy(() =>
  import("./Presentation").then((module) => ({ default: module.Presentation })),
);

export const MoreInterviewCassettesSlider: FC<Props> = ({ moreInterviewCassette }) => {
  return (
    <Suspense fallback={null}>
      <Presentation moreInterviewCassette={moreInterviewCassette} />
    </Suspense>
  );
};
