import { FC, ReactNode } from "react";
import { RecipeVariants } from "@vanilla-extract/recipes";
import * as styles from "./styles.css";

type Props = {
  children: ReactNode;
  className?: string;
} & RecipeVariants<typeof styles.outer>;

export const GuidanceBox: FC<Props> = ({ backgroundColor = "gray", children, className }) => (
  <div className={`${styles.outer({ backgroundColor })} ${className}`}>{children}</div>
);
