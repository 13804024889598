import { FC } from "react";
import { SectionTitle } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/SectionTitle";
import { SectionWithTitleAndLabel } from "@userFrontend/components/SectionWithTitleAndLabel";
import { commonInner } from "@userFrontend/features/clientReview/components/ClientReviewInputs/styles.css";
import { MODEL_ATTRIBUTE_NAME } from "@root/utils/constants";
import { ClientSelect } from "./uiParts/ClientSelect";
import { EntryClientSelect } from "./uiParts/EntryClientSelect";
import * as styles from "./styles.css";
import { EmploymentStatusRadio } from "./uiParts/EmploymentStatusRadio";
import { SelectBoxContents } from "./uiParts/SelectBoxContents";
import { Props } from "./type";

export const BasicAndEmployment: FC<Props> = ({
  questions,
  client,
  clientRef,
  handleShowModal,
  clientErrorText,
  employmentStatusValue,
  handleEmploymentStatusChange,
  employmentStatusErrorText,
  employmentStatusRef,
  continuedServiceYearsValue,
  handleContinuedServiceYearSelect,
  continuedServiceYearsErrorText,
  continuedServiceYearsRef,
  businessTypeValue,
  handleBusinessTypeSelect,
  businessTypesErrorText,
  businessTypeIdRef,
  occupationValue,
  handleOccupationSelect,
  occupationsErrorText,
  occupationIdRef,
  employmentValue,
  handleEmploymentSelect,
  employmentsErrorText,
  employmentIdRef,
  isShowSearchModal,
  handleCloseModal,
  clientNameValue,
  shopNameValue,
  clientNameErrorText,
  shopNameErrorText,
  handleClientSearch,
  handleChangeClientNameValue,
  handleChangeShopNameValue,
  searchResults,
  handleClientSelect,
  isShowClientsDetail,
  handleShowTextButton,
  searchResultErrorText,
  displaySearchText,
  isSearching,
  handleReturnToMyPageTop,
  userContactPath,
  checkedJobEntryId,
  jobEntryButtons,
}) => (
  <>
    <SectionTitle text="投稿先を選ぶ" />
    <div className={commonInner}>
      <div className={styles.inputOuter}>
        <SectionWithTitleAndLabel isRequired title="投稿先" containerRef={clientRef}>
          {jobEntryButtons?.length ? (
            <EntryClientSelect
              client={client}
              handleShowModal={handleShowModal}
              isShowSearchModal={isShowSearchModal}
              handleCloseModal={handleCloseModal}
              clientNameValue={clientNameValue}
              shopNameValue={shopNameValue}
              clientNameErrorText={clientNameErrorText}
              shopNameErrorText={shopNameErrorText}
              handleClientSearch={handleClientSearch}
              handleChangeClientNameValue={handleChangeClientNameValue}
              handleChangeShopNameValue={handleChangeShopNameValue}
              searchResults={searchResults}
              handleClientSelect={handleClientSelect}
              isShowClientsDetail={isShowClientsDetail}
              handleShowTextButton={handleShowTextButton}
              searchResultErrorText={searchResultErrorText}
              displaySearchText={displaySearchText}
              isSearching={isSearching}
              handleReturnToMyPageTop={handleReturnToMyPageTop}
              userContactPath={userContactPath}
              checkedJobEntryId={checkedJobEntryId}
              jobEntryButtons={jobEntryButtons}
              clientErrorText={clientErrorText}
            />
          ) : (
            <ClientSelect
              client={client}
              clientErrorText={clientErrorText}
              handleShowModal={handleShowModal}
              isShowSearchModal={isShowSearchModal}
              handleCloseModal={handleCloseModal}
              clientNameValue={clientNameValue}
              shopNameValue={shopNameValue}
              clientNameErrorText={clientNameErrorText}
              shopNameErrorText={shopNameErrorText}
              handleClientSearch={handleClientSearch}
              handleChangeClientNameValue={handleChangeClientNameValue}
              handleChangeShopNameValue={handleChangeShopNameValue}
              searchResults={searchResults}
              handleClientSelect={handleClientSelect}
              isShowClientsDetail={isShowClientsDetail}
              handleShowTextButton={handleShowTextButton}
              searchResultErrorText={searchResultErrorText}
              displaySearchText={displaySearchText}
              isSearching={isSearching}
              handleReturnToMyPageTop={handleReturnToMyPageTop}
              userContactPath={userContactPath}
            />
          )}
        </SectionWithTitleAndLabel>
      </div>
    </div>
    <SectionTitle text="就業情報" />
    <div>
      <div className={commonInner}>
        <div className={styles.inputOuter}>
          <SectionWithTitleAndLabel title="就業状況" isRequired containerRef={employmentStatusRef}>
            {questions.employmentStatus && (
              <EmploymentStatusRadio
                employmentStatus={questions.employmentStatus}
                handleEmploymentStatusChange={handleEmploymentStatusChange}
                employmentStatusValue={employmentStatusValue}
                employmentStatusErrorText={employmentStatusErrorText}
              />
            )}
          </SectionWithTitleAndLabel>
        </div>
        <div className={styles.inputOuter}>
          <SectionWithTitleAndLabel
            title="この職場での勤続年数"
            isRequired
            containerRef={continuedServiceYearsRef}
          >
            {questions.continuedServiceYearsSelectOptions && (
              <SelectBoxContents
                categoryName="continuedServiceYear"
                value={continuedServiceYearsValue}
                handleSelect={handleContinuedServiceYearSelect}
                selectOptions={questions.continuedServiceYearsSelectOptions}
                errorText={continuedServiceYearsErrorText}
              />
            )}
          </SectionWithTitleAndLabel>
        </div>
        <div className={styles.inputOuter}>
          <SectionWithTitleAndLabel title="職種" isRequired containerRef={businessTypeIdRef}>
            {questions.businessTypesSelectOptions && (
              <SelectBoxContents
                categoryName="businessType"
                value={businessTypeValue}
                handleSelect={handleBusinessTypeSelect}
                selectOptions={questions.businessTypesSelectOptions}
                errorText={businessTypesErrorText}
              />
            )}
          </SectionWithTitleAndLabel>
        </div>
        <div className={styles.inputOuter}>
          <SectionWithTitleAndLabel
            title={MODEL_ATTRIBUTE_NAME.workContent.occupation}
            isRequired
            containerRef={occupationIdRef}
          >
            {questions.occupationsSelectOptions && (
              <SelectBoxContents
                categoryName="occupation"
                value={occupationValue}
                handleSelect={handleOccupationSelect}
                selectOptions={questions.occupationsSelectOptions}
                errorText={occupationsErrorText}
              />
            )}
          </SectionWithTitleAndLabel>
        </div>
        <div className={styles.inputOuter}>
          <SectionWithTitleAndLabel title="雇用形態" isRequired containerRef={employmentIdRef}>
            {questions.employmentsSelectOptions && (
              <SelectBoxContents
                categoryName="employment"
                value={employmentValue}
                handleSelect={handleEmploymentSelect}
                selectOptions={questions.employmentsSelectOptions}
                errorText={employmentsErrorText}
              />
            )}
          </SectionWithTitleAndLabel>
        </div>
      </div>
    </div>
  </>
);
