import { FC } from "react";
import { Information } from "@userFrontend/features/clientReview/components/Information";
import { GuidanceBox } from "@root/shared/components/GuidanceBox";
import { TextLink } from "@root/shared/components/TextLink";
import * as styles from "./styles.css";

type Props = {
  mypageTopPath: string;
};

export const ClientReviewLimitReached: FC<Props> = ({ mypageTopPath }) => (
  <div className={styles.informationWrapper}>
    <Information />
    <GuidanceBox backgroundColor="gray" className={styles.guidanceBoxOuter}>
      <TextLink href={mypageTopPath}>マイページに戻る</TextLink>
    </GuidanceBox>
  </div>
);
