import { ComponentPropsWithRef, FC, forwardRef } from "react";
import { variant } from "./styles.css";

type Props = Omit<ComponentPropsWithRef<"input">, "type"> & {
  gaLabel?: string;
  gaAction?: string;
  hasError?: boolean;
  dataName?: string;
};

export const Checkbox: FC<Props> = forwardRef(
  (
    {
      name,
      value,
      id,
      className,
      gaLabel,
      gaAction,
      defaultChecked,
      checked,
      disabled,
      hasError,
      onChange,
      dataName,
    },
    ref,
  ) => (
    <input
      type="checkbox"
      name={name}
      value={value}
      id={id}
      className={`${variant[hasError ? "hasError" : "default"]} ${className || ""}`}
      data-ga-label={gaLabel}
      data-ga-action={gaAction}
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      ref={ref}
      onChange={onChange}
      data-name={dataName}
    />
  ),
);
Checkbox.displayName = "Checkbox";
