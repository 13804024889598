import { FC, ComponentProps } from "react";
import { JobEntryRadioButton } from "@userFrontend/components/JobEntryRadioButton";
import { UserRewardPromotionModal } from "./uiParts/UserRewardPromotionModal";

type ModalProps = {
  showModal: boolean;
  closeHandler: () => void;
  userContactUrl: string;
};

type RadioButtonProps = {
  jobEntryButtons: Pick<
    ComponentProps<typeof JobEntryRadioButton>,
    "clientName" | "brandName" | "workTitle" | "jobEntryId" | "disabled"
  >[];
  checkedJobEntryId: number | null;
  isSp: boolean;
  isError: boolean;
  userContactUrl: string;
  handleChangeEntryClient: (jobEntryId: number) => void;
};

export const Presentation: FC<ModalProps & RadioButtonProps> = ({
  showModal,
  closeHandler,
  userContactUrl,
  jobEntryButtons,
  checkedJobEntryId,
  isSp,
  isError,
  handleChangeEntryClient,
}) => {
  return (
    <>
      <UserRewardPromotionModal
        showModal={showModal}
        closeHandler={closeHandler}
        userContactUrl={userContactUrl}
      />
      {jobEntryButtons.map(({ clientName, brandName, workTitle, jobEntryId, disabled }) => (
        <JobEntryRadioButton
          key={jobEntryId}
          clientName={clientName}
          brandName={brandName}
          workTitle={workTitle}
          jobEntryId={jobEntryId}
          disabled={disabled}
          checked={checkedJobEntryId === jobEntryId}
          isSp={isSp}
          isError={isError}
          userContactUrl={userContactUrl}
          handleChangeEntryClient={handleChangeEntryClient}
          name="form_user_frontend_user_reward_step1[job_entry_id]"
        />
      ))}
    </>
  );
};
