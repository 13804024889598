import { FC } from "react";
import { PopOver } from "@root/shared/components/PopOver";

export const KeepAppealPopOver: FC = () => (
  <PopOver autoHide arrowPosition="topCenter" bottom="-54px">
    以前もこの求人を閲覧しました。
    <br />
    キープしますか？
  </PopOver>
);
