import { FC, useState } from "react";
import Modal from "@root/shared/components/Modal";
import { AppealMessage } from "@userFrontend/features/entry/components/MatchingSupportInfoModal/uiParts/AppealMessage";
import { ContactLinks } from "@userFrontend/features/entry/components/MatchingSupportInfoModal/uiParts/ContactLinks";

type Props = {
  showModal: boolean;
  isSp: boolean;
};

export const MatchingSupportInfoModal: FC<Props> = ({ showModal, isSp }) => {
  const [isOpen, setIsOpen] = useState(showModal);
  const handleClose = () => {
    setIsOpen(false);
  };
  const deviceType = isSp ? "sp" : "pc";

  return (
    <Modal
      headerTitle="辞退しました。"
      isShow={isOpen}
      onClose={handleClose}
      spAppearanceType="bottom"
      pcWidthType="large"
      headerTitleStyle="white"
    >
      <>
        <AppealMessage deviceType={deviceType} />
        <ContactLinks deviceType={deviceType} />
      </>
    </Modal>
  );
};
