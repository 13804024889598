import styled from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";

export const StyledContentsOuter = styled.div<{ is1column: boolean }>`
  .is-pc & {
    ${Breakpoints.LARGE} {
      &[data-position="top"] {
        display: ${(props) => (props.is1column ? "block" : "flex")};
        justify-content: space-between;
        margin-top: 24px;
        margin-bottom: 24px;
      }
    }
  }
`;

export const StyledImageContents = styled.div<{ is1column: boolean }>`
  margin-left: -16px;
  margin-right: -16px;
  text-align: center;
  margin-top: 10px;
  .is-pc & {
    ${Breakpoints.MEDIUM} {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      flex-basis: 67.5%;
      max-width: ${(props) => (props.is1column ? "657px" : "auto")};
      margin: ${(props) => (props.is1column ? "0 auto" : "0")};
    }
  }
`;

export const StyledContents = styled.div`
  &[data-position="top"] {
    margin-top: 28px;
  }

  .is-pc & {
    ${Breakpoints.MEDIUM} {
      &[data-style-column="2"] {
        margin-top: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
      }
    }
    ${Breakpoints.LAEGE} {
      &[data-style-column="2"] {
        max-width: 293px;
      }
    }
  }
`;

export const StyledButtonBasicStyles = styled.a`
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  text-decoration: none;
  font-size: 16px;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
`;

export const StyledEntryButton = styled(StyledButtonBasicStyles)<{ isFixed: boolean }>`
  font-weight: bold;
  color: ${Colors.WHITE};
  background-color: ${Colors.PRIMARY};
  padding: ${(props) => (props.isFixed ? "13px 0" : "16.5px 0")};

  .is-pc & {
    ${Breakpoints.MEDIUM} {
      &:hover {
        background: rgba(251, 99, 65, 0.8);
        opacity: 0.8;
        border-color: ${Colors.WHITE};
        box-shadow:
          0px 0px 8px ${Colors.PRIMARY},
          inset 0px 0px 1px ${Colors.PRIMARY};
      }
    }
  }
`;

export const StyledFrameButton = styled(StyledButtonBasicStyles)`
  font-weight: bold;
  font-size: 14px;
  background-color: ${Colors.WHITE};
  padding: 10px 0;
  color: ${Colors.LIGHT_BLACK};
  border-radius: 4px;
  border: 1px solid ${Colors.MEDIUM_GREY};
  z-index: 1;

  i {
    margin-right: 8px;
  }

  .is-pc & {
    ${Breakpoints.MEDIUM} {
      padding: 9px 0;

      &:hover {
        opacity: 0.8;
        border-color: ${Colors.WHITE};
        box-shadow:
          0px 0px 8px ${Colors.MEDIUM_GREY},
          inset 0px 0px 1px ${Colors.MEDIUM_GREY};
      }
    }
  }
`;

export const StyledSalonTourButton = styled.button`
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  text-decoration: none;
  font-size: 16px;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
  font-weight: bold;
  font-size: 14px;
  background-color: ${Colors.WHITE};
  padding: 10px 0;
  color: ${Colors.LIGHT_BLACK};
  border-radius: 4px;
  border: 1px solid ${Colors.MEDIUM_GREY};
  z-index: 1;

  i {
    margin-right: 8px;
  }

  .is-pc & {
    ${Breakpoints.MEDIUM} {
      padding: 9px 0;

      &:hover {
        opacity: 0.8;
        border-color: ${Colors.WHITE};
        box-shadow:
          0px 0px 8px ${Colors.MEDIUM_GREY},
          inset 0px 0px 1px ${Colors.MEDIUM_GREY};
      }
    }
  }
`;

export const StyledButtonGroup = styled.div<{ isBlock: boolean }>`
  .is-pc & {
    ${Breakpoints.MEDIUM} {
      &[data-style-column="1"] {
        justify-content: space-between;
        align-items: end;
        display: ${(props) => (props.isBlock ? "block" : "flex")};
      }
    }
  }
`;

export const StyledBottomContent = styled.div<{ is2column: boolean }>`
  width: ${(props) => (props.is2column ? "48%" : "100%")};

  .is-pc & {
    ${Breakpoints.MEDIUM} {
      width: 100%;
    }
  }
`;

export const StyledEntriedButton = styled.div`
  font-weight: bold;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  font-size: 16px;
  padding: 9px 0;
  background-color: ${Colors.LIGHT_GREY};
  color: ${Colors.LIGHT_BLACK};

  .is-pc & {
    ${Breakpoints.MEDIUM} {
      padding: 16.5px 0;

      &[data-style-column="1"] {
        max-width: 468px;
        margin: 0 auto;
      }
    }
  }
`;

export const StyledWorkCodeText = styled.div`
  font-size: 10px;
  text-align: center;
  margin-top: 3px;
`;

export const StyledBottomContents = styled.div<{ addMargin: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 12px;
  margin-bottom: ${(props) => (props.addMargin ? "20px" : "")};

  .is-pc & {
    ${Breakpoints.MEDIUM} {
      &[data-style-column="1"] {
        max-width: 468px;
        margin: 0 auto;
        margin-top: 12px;
      }
    }
  }
`;

export const StyledTitleText = styled.p`
  padding-bottom: 8px;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.4;
  color: ${Colors.DEEP_BLACK};
  margin-top: 10px;

  .is-pc & {
    ${Breakpoints.MEDIUM} {
      padding-bottom: 0;
    }
  }
`;

export const StyledAwardsLabels = styled.div`
  margin-left: -16px;
  margin-bottom: 8px;

  .is-pc & {
    ${Breakpoints.MEDIUM} {
      margin-left: 0;
    }
  }
`;

export const StyledAwardsLabel = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  min-height: 24px;
  padding: 4px 6px 4px 21px;
  background-repeat: no-repeat;
  background-size: 13px auto;
  background-position: 6px center;
  color: ${Colors.WHITE};
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 8px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    right: -4px;
    width: 4px;
    height: 50%;
  }

  &::before {
    top: 0;
    clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
  }

  &::after {
    bottom: 0;
    clip-path: polygon(0% 100%, 0% 0%, 100% 100%);
  }

  &[data-rank="gold"] {
    background-color: ${Colors.AWARDS_LABEL_GOLD};
    background-image: url("/images/user_frontend/global/icon/icn_awards_gold.svg");

    &::before,
    &::after {
      background-color: ${Colors.AWARDS_LABEL_GOLD};
    }
  }

  &[data-rank="silver"] {
    background-color: ${Colors.AWARDS_LABEL_SILVER};
    background-image: url("/images/user_frontend/global/icon/icn_awards_silver.svg");

    &::before,
    &::after {
      background-color: ${Colors.AWARDS_LABEL_SILVER};
    }
  }

  &[data-rank="bronze"] {
    background-color: ${Colors.AWARDS_LABEL_BRONZE};
    background-image: url("/images/user_frontend/global/icon/icn_awards_bronze.svg");

    &::before,
    &::after {
      background-color: ${Colors.AWARDS_LABEL_BRONZE};
    }
  }

  &[data-rank="selected"] {
    background-color: #e67e9b;
    background-image: url("/images/user_frontend/global/icon/icn_awards_selected.svg");

    &::before,
    &::after {
      background-color: #e67e9b;
    }
  }
`;

export const StyledFixBtnList = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  bottom: -62px;
  z-index: 4;
  background-color: ${Colors.WHITE};
  padding: 8px 16px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  transition: bottom 0.2s;
  box-shadow: 0px -2px 2px rgba(153, 153, 153, 0.2);
`;

export const StyledFixTelBtn = styled.a`
  color: ${Colors.PRIMARY};
  font-size: 16px;
  border: 1px solid ${Colors.PRIMARY};
  padding: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-decoration: none;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  i {
    margin-right: 8px;
  }
`;

export const StyledFixTelButtonOuter = styled.div`
  width: 40%;
`;

export const StyledFixEntryButtonOuter = styled.div<{ is2column: boolean }>`
  width: ${(props) => (props.is2column ? "58%" : "100%")};
`;
