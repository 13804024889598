import { FC } from "react";
import { Checkbox } from "@root/shared/components/Checkbox";
import { SectionWithTitleAndLabel } from "@userFrontend/components/SectionWithTitleAndLabel";
import { ErrorInformationText } from "@userFrontend/components/ErrorInformationText";
import {
  SelectOptionQuestions,
  ErrorText,
} from "@userFrontend/features/reward/components/ContinuedRewardInputs/type";
import * as styles from "./styles.css";

type Props = {
  questions: SelectOptionQuestions;
  categoryName: string;
  checkboxValues: Record<string, number[]>;
  handleCheckboxChange: (category: string, optionId: number) => void;
  errorText?: ErrorText;
  isDisabled: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
};

export const RewardContent: FC<Props> = ({
  questions,
  categoryName,
  checkboxValues,
  handleCheckboxChange,
  errorText,
  isDisabled,
  containerRef,
}) => {
  return (
    <SectionWithTitleAndLabel
      isRequired
      title="あなたの職場の良いところを教えてください"
      text="最大5つまで選択してください。"
      containerRef={containerRef}
    >
      {errorText && <ErrorInformationText text={errorText} />}
      <div className={styles.checkboxesOuter}>
        {questions.map((question) => {
          const id = `checkbox-${question.index}-${question.optionId}`;
          const checked = checkboxValues[categoryName]?.includes(question.optionId) || false;
          return (
            <div key={question.optionId} className={styles.outer}>
              <span className={styles.checkboxOuter}>
                <Checkbox
                  name={categoryName}
                  id={id}
                  checked={checked}
                  onChange={() => handleCheckboxChange(categoryName, question.optionId)}
                  hasError={!!errorText}
                  disabled={isDisabled}
                />
              </span>
              <label htmlFor={id} className={styles.label}>
                {question.optionText}
              </label>
            </div>
          );
        })}
      </div>
    </SectionWithTitleAndLabel>
  );
};
