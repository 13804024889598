import { FC } from "react";
import Modal from "@root/shared/components/Modal";
import { Link } from "@root/shared/components/Link";
import { Button } from "@root/shared/components/Button";
import * as styles from "./styles.css";

type Props = {
  isShow: boolean;
  closeModalHandler: () => void;
  deleteBackupHandler: () => void;
  linkToMypageHandler: () => void;
  restoreUrl: string;
};

export const Presentation: FC<Props> = ({
  isShow,
  closeModalHandler,
  deleteBackupHandler,
  linkToMypageHandler,
  restoreUrl,
}) => {
  return (
    <Modal
      isShow={isShow}
      onClose={closeModalHandler}
      spAppearanceType="center"
      canOutsideClick={false}
      pcWidthType="small"
      headerTitleStyle="white"
    >
      <div className={styles.modalBody}>
        <p className={styles.strongMsg}>バックアップを復元しますか？</p>
        <p className={styles.msg}>以前の入力内容の、自動バックアップが残っています。</p>
        <div className={styles.buttonsWrapper}>
          {/* TODO: 理想はurlに遷移ではなく、onClickで画面遷移することなく復元させたいです。
                    勤続支援金申請フォームのすべてがReact化されたらそのように修正してください。 */}
          <Link theme="primary" href={restoreUrl} data-ga-action="user_rewards_rewrite_popup">
            復元する
          </Link>
          <Button theme="tertiary" onClick={deleteBackupHandler}>
            削除して入力しなおす
          </Button>
        </div>
        <Button onClick={linkToMypageHandler} className={styles.transparentButton}>
          マイページトップへ戻る
        </Button>
      </div>
    </Modal>
  );
};
