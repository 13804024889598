import { FC, ReactNode } from "react";
import * as styles from "./styles.css";

type Props = {
  text: string | ReactNode;
  icon?: ReactNode;
  isAlert?: boolean;
  className?: string;
};

export const InformationText: FC<Props> = ({ text, icon, isAlert, className }) => (
  <p className={`${styles.outer[isAlert ? "isAlert" : "default"]} ${className}`}>
    {icon && <span className={styles.iconOuter}>{icon}</span>}
    <span>{text}</span>
  </p>
);
