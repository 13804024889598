import { ComponentType } from "react";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: Promise<{ default: ComponentType<any> }>;
  retriesLeft?: number;
};

const DEFAULT_RETRY_COUNT = 5;
const INTERVAL_TIME = 1000;

export const retryLazyLoad = ({
  fn,
  retriesLeft = DEFAULT_RETRY_COUNT,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: Props): Promise<{ default: ComponentType<any> }> => {
  return new Promise((resolve, reject) => {
    fn.then(resolve).catch((error: Error) => {
      if (retriesLeft <= 1) {
        reject(error);
        return;
      }
      setTimeout(() => {
        retryLazyLoad({ fn, retriesLeft: retriesLeft - 1 }).then(resolve, reject);
      }, INTERVAL_TIME);
    });
  });
};
