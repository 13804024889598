import { FC } from "react";
import { Information } from "@userFrontend/features/clientReview/components/Information";
import { Button } from "@root/shared/components/Button";
import { EMPLOYMENT_TYPE } from "@root/utils/constants";
import { RestoreClientReviewInputsModal } from "./uiParts/RestoreClientReviewInputsModal";
import { BasicAndEmployment } from "./uiParts/BasicAndEmployment";
import { TreatmentAndCulture } from "./uiParts/TreatmentAndCulture";
import { WorkplaceReviewFeature } from "./uiParts/WorkplaceReviewFeature";
import { SalaryData } from "./uiParts/SalaryData";
import { WorkplaceReviewThought } from "./uiParts/WorkplaceReviewThought";
import { Confirm } from "./uiParts/Confirm";
import * as styles from "./styles.css";

// types
import { PresentationProps } from "./type";

export const Presentation: FC<PresentationProps> = ({
  currentStep,
  questions,
  client,
  businessTypeValue,
  employmentStatusValue,
  continuedServiceYearsValue,
  occupationValue,
  employmentValue,
  monthlyIncomeValue,
  minimumGuaranteedIncomeValue,
  grossMonthlyIncomeValue,
  hourlyWageValue,
  noGuaranteedIncomeFlag,
  noBonusFlag,
  hiddenFlag,
  radioValues,
  checkboxValues,
  textAreaValue,
  bonusInputsProps,
  errorTexts,
  handleShowModal,
  handleBusinessTypeSelect,
  handleRadioChange,
  handleOccupationSelect,
  handleEmploymentSelect,
  handleMonthlyIncomeChange,
  handleMinimumGuaranteedIncomeChange,
  handleGrossMonthlyIncomeChange,
  handleHourlyWageChange,
  handleMinimumGuaranteedIncomeCheckboxChange,
  handleBonusCheckboxChange,
  handleHiddenFlagCheckboxChange,
  handleCheckboxChange,
  handleTextAreaChange,
  handleEmploymentStatusChange,
  handleContinuedServiceYearSelect,
  handleClickBackButton,
  handleClickSubmitButton,
  isShowBackupModal,
  restoreBackupPath,
  deleteBackupPath,
  termsPath,
  guidelinePath,
  handleRestore,
  inputContainerRefs,
  isAvailableSubmit,
  isShowSearchModal,
  handleCloseModal,
  clientNameValue,
  shopNameValue,
  handleClientSearch,
  handleChangeClientNameValue,
  handleChangeShopNameValue,
  searchResults,
  handleClientSelect,
  isShowClientsDetail,
  handleShowTextButton,
  searchResultErrorText,
  displaySearchText,
  isSearching,
  handleReturnToMyPageTop,
  userContactPath,
  salaryArray,
  bonusArray,
  checkedJobEntryId,
  jobEntryButtons,
}) => {
  const renderContent = () => {
    if (currentStep === 5) {
      return (
        <Confirm
          questions={questions}
          clientNameValue={client?.clientName}
          brandNameValue={client?.brandName}
          workTitleValue={client?.workTitle}
          businessTypeValue={businessTypeValue}
          occupationValue={occupationValue}
          employmentValue={employmentValue}
          employmentStatusValue={Number(employmentStatusValue) ?? undefined}
          continuedServiceYearsValue={continuedServiceYearsValue}
          checkboxValues={checkboxValues}
          radioValue={radioValues}
          textAreaValue={textAreaValue}
          termsPath={termsPath}
          guidelinePath={guidelinePath}
          handleClickBackButton={handleClickBackButton}
          handleClickSubmitButton={handleClickSubmitButton}
          salaryArray={salaryArray}
          bonusArray={bonusArray}
        />
      );
    }
    return (
      <div className={styles.wrapper}>
        <Information />
        {/* 基本情報（投稿先企業） 就業情報（就業状況・勤続年数・職種・雇用形態） */}
        <BasicAndEmployment
          // 投稿先企業選択
          searchResults={searchResults}
          isShowSearchModal={isShowSearchModal}
          displaySearchText={displaySearchText}
          isSearching={isSearching}
          userContactPath={userContactPath}
          jobEntryButtons={jobEntryButtons}
          isShowClientsDetail={isShowClientsDetail}
          checkedJobEntryId={checkedJobEntryId}
          // 就業状況の質問項目（ラジオボタン・セレクトボックス）
          questions={questions.basicAndEmploymentQuestions}
          // values
          client={client ?? undefined}
          employmentStatusValue={employmentStatusValue ?? undefined}
          continuedServiceYearsValue={continuedServiceYearsValue ?? undefined}
          businessTypeValue={businessTypeValue ?? undefined}
          occupationValue={occupationValue ?? undefined}
          employmentValue={employmentValue ?? undefined}
          clientNameValue={clientNameValue}
          shopNameValue={shopNameValue}
          // error texts
          clientErrorText={errorTexts.client}
          employmentStatusErrorText={errorTexts.employmentStatus}
          continuedServiceYearsErrorText={errorTexts.continuedServiceYears}
          businessTypesErrorText={errorTexts.businessType}
          occupationsErrorText={errorTexts.occupation}
          employmentsErrorText={errorTexts.employment}
          shopNameErrorText={errorTexts.shopName}
          searchResultErrorText={searchResultErrorText}
          // handlers
          handleShowModal={handleShowModal}
          handleBusinessTypeSelect={handleBusinessTypeSelect}
          handleEmploymentStatusChange={handleEmploymentStatusChange}
          handleContinuedServiceYearSelect={handleContinuedServiceYearSelect}
          handleOccupationSelect={handleOccupationSelect}
          handleEmploymentSelect={handleEmploymentSelect}
          handleCloseModal={handleCloseModal}
          handleClientSearch={handleClientSearch}
          handleClientSelect={handleClientSelect}
          handleChangeClientNameValue={handleChangeClientNameValue}
          handleChangeShopNameValue={handleChangeShopNameValue}
          handleShowTextButton={handleShowTextButton}
          handleReturnToMyPageTop={handleReturnToMyPageTop}
          // refs
          clientRef={inputContainerRefs?.current.client}
          businessTypeIdRef={inputContainerRefs?.current?.businessTypeId}
          employmentStatusRef={inputContainerRefs?.current?.employmentStatus}
          continuedServiceYearsRef={inputContainerRefs?.current.continuedServiceYears}
          occupationIdRef={inputContainerRefs?.current?.occupationId}
          employmentIdRef={inputContainerRefs?.current?.employmentId}
        />
        {/* 待遇面の口コミ 社風面の口コミ */}
        <TreatmentAndCulture
          // 質問項目（チェックボックス）
          questions={questions.treatmentAndCultureQuestions}
          // values
          checkboxValues={checkboxValues}
          // error texts
          treatmentsErrorText={errorTexts.treatments}
          culturesErrorText={errorTexts.cultures}
          // handlers
          handleCheckboxChange={handleCheckboxChange}
          isDisabled={false} // TODO: fixme
          treatmentsRef={inputContainerRefs?.current?.treatments}
          culturesRef={inputContainerRefs?.current?.cultures}
        />
        {/* 職場の口コミ（特徴） */}
        <WorkplaceReviewFeature
          // 質問項目（ラジオボタン）
          questions={questions.workplaceReviewFeatureQuestions}
          // values
          radioValues={radioValues}
          // error texts
          errorTexts={errorTexts}
          // handlers
          handleRadioChange={handleRadioChange}
          atmosphereRef={inputContainerRefs?.current?.atmosphere}
          salaryStandardRef={inputContainerRefs?.current?.salaryStandard}
          debutRef={inputContainerRefs?.current?.debut}
          educationRef={inputContainerRefs?.current?.education}
          workTimeRef={inputContainerRefs?.current?.workTime}
          salaryChangeRef={inputContainerRefs?.current?.salaryChange}
        />
        {/* 給与データ */}
        {typeof employmentValue !== "undefined" && employmentValue !== EMPLOYMENT_TYPE.OTHER_ID && (
          <SalaryData
            // values
            monthlyIncomeValue={monthlyIncomeValue}
            minimumGuaranteedIncomeValue={minimumGuaranteedIncomeValue}
            grossMonthlyIncomeValue={grossMonthlyIncomeValue}
            hourlyWageValue={hourlyWageValue}
            noGuaranteedIncomeFlag={noGuaranteedIncomeFlag}
            noBonusFlag={noBonusFlag}
            hiddenFlag={hiddenFlag}
            employmentValue={employmentValue}
            // error texts
            monthlyIncomeErrorText={errorTexts.monthlyIncome}
            minimumGuaranteedIncomeErrorText={errorTexts.minimumGuaranteedIncome}
            grossMonthlyIncomeErrorText={errorTexts.grossMonthlyIncome}
            hourlyWageErrorText={errorTexts.hourlyWage}
            bonusInputsProps={{
              ...bonusInputsProps,
              errorBonusPaymentYearOptionsText: errorTexts.paymentYear,
              errorBonusPaymentCountOptionsText: errorTexts.paymentCount,
              errorBonusTotalAmountText: errorTexts.totalAmount,
            }}
            // handlers
            handleMonthlyIncomeChange={handleMonthlyIncomeChange}
            handleMinimumGuaranteedIncomeChange={handleMinimumGuaranteedIncomeChange}
            handleGrossMonthlyIncomeChange={handleGrossMonthlyIncomeChange}
            handleHourlyWageChange={handleHourlyWageChange}
            handleMinimumGuaranteedIncomeCheckboxChange={
              handleMinimumGuaranteedIncomeCheckboxChange
            }
            handleBonusCheckboxChange={handleBonusCheckboxChange}
            handleHiddenFlagCheckboxChange={handleHiddenFlagCheckboxChange}
            // refs
            monthlyIncomeRef={inputContainerRefs?.current?.monthlyIncome}
            minimumGuaranteedIncomeRef={inputContainerRefs?.current?.minimumGuaranteedIncome}
            grossMonthlyIncomeRef={inputContainerRefs?.current?.grossMonthlyIncome}
            hourlyWageRef={inputContainerRefs?.current?.hourlyWage}
            bonusRef={inputContainerRefs?.current?.bonus}
          />
        )}
        {/* 職場の口コミ（あなたの考え/想い） */}
        <WorkplaceReviewThought
          // 質問項目（テキストエリア）
          questions={questions.workplaceReviewThoughtQuestions}
          // values
          textAreaValues={textAreaValue}
          // error texts
          errorTexts={errorTexts}
          // handlers
          handleTextAreaChange={handleTextAreaChange}
          workplaceMatchingRef={inputContainerRefs?.current?.workplaceMatching}
          interestRewardRef={inputContainerRefs?.current?.interestReward}
          challengeRef={inputContainerRefs?.current?.challenge}
          expectationRef={inputContainerRefs?.current?.expectation}
        />
        <div className={styles.submitButtonContainer}>
          <div className={styles.submitButtonOuter}>
            <Button
              theme={isAvailableSubmit ? "primary" : "disabled"}
              onClick={handleClickSubmitButton}
            >
              次へ
            </Button>
          </div>
        </div>
        <RestoreClientReviewInputsModal
          isShowBackupModal={isShowBackupModal}
          restoreBackupPath={restoreBackupPath}
          deleteBackupPath={deleteBackupPath}
          handleRestore={handleRestore}
        />
      </div>
    );
  };

  return <>{renderContent()}</>;
};
