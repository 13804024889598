import { FC, ReactNode, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { ArrowPosition } from "@root/shared/types/type";
import { usePopOver } from "./usePopOver";
import { Presentation } from "./Presentation";

type Props = {
  children: ReactNode; // PopOverにはテキストのコンテンツのみ入る想定
  className?: string;
  arrowPosition: ArrowPosition;
  autoHide?: boolean;
  autoHideWaitingTime?: number;
  bottom?: string;
  top?: string;
  // 親要素を超えて要素を表示したい場合に指定
  right?: string; // ex) 右に50pxはみ出したい場合 rightに"-50px を指定"
  left?: string;
  zIndex?: string;
};

export const PopOver: FC<Props> = ({
  children,
  className,
  arrowPosition,
  autoHide = false,
  autoHideWaitingTime = 5000,
  bottom = "auto",
  top = "auto",
  right = "auto",
  left = "auto",
  zIndex = "auto",
}) => {
  const { ref, inView } = useInView({
    threshold: 1.0,
    triggerOnce: true, // 最初の一度だけ実行
  });

  const { isOpacityZero, isDisplayNone, hideWithDelay, autoHidePopOver } = usePopOver();

  useEffect(() => {
    if (inView && autoHide) {
      autoHidePopOver(autoHideWaitingTime);
    }
  }, [inView, autoHide, autoHidePopOver, autoHideWaitingTime]);

  return (
    <Presentation
      className={className}
      arrowPosition={arrowPosition}
      isOpacityZero={isOpacityZero}
      isDisplayNone={isDisplayNone}
      elementRef={ref}
      hideWithDelay={hideWithDelay}
      right={right}
      left={left}
      bottom={bottom}
      top={top}
      zIndex={zIndex}
    >
      {children}
    </Presentation>
  );
};
