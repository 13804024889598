import { FC } from "react";
import { InformationText } from "@root/shared/components/InformationText";
import { RewardGuidanceBox } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/RewardGuidanceBox";
import { CautionaryNoteBox } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/CautionaryNoteBox";
import * as styles from "./styles.css";

export const Information: FC = () => (
  <div className={styles.informationWrapper}>
    <InformationText
      text={
        <>
          業界の市場調査の一環として口コミのご記載をお願いしております。
          <br />
          ご回答いただいた口コミは、リジョブサイトや関連サイトに公開します。
          <br />
          アンケートのご回答者様へ、<b>カフェギフト500円分</b>をお渡しいたします。
        </>
      }
    />
    <div className={styles.boxOuter}>
      <CautionaryNoteBox />
    </div>
    <div className={styles.boxOuter}>
      <RewardGuidanceBox />
    </div>
  </div>
);
