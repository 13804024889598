import { FC } from "react";
import { SectionTitle } from "@userFrontend/features/reward/components/ContinuedRewardInputs/uiParts/SectionTitle";
import { SectionWithTitleAndLabel } from "@userFrontend/components/SectionWithTitleAndLabel";
import { ErrorInformationText } from "@userFrontend/components/ErrorInformationText";
import { InformationText } from "@root/shared/components/InformationText";
import { Icon } from "@root/shared/components/Icon";
import { ImageFileUploader } from "@userFrontend/features/reward/components/ImageFileUploader";
import * as styles from "./styles.css";

type Props = {
  errorText?: string;
  imagesRef: React.RefObject<HTMLDivElement>;
  handleClickSelectButton: () => void;
  handleChangeFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickDeleteButton: (index: number) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  previewUrlArray: string[];
  isLoading: boolean;
};

export const Step5: FC<Props> = ({
  errorText,
  imagesRef,
  handleClickSelectButton,
  handleChangeFile,
  handleClickDeleteButton,
  fileInputRef,
  previewUrlArray,
  isLoading,
}) => {
  return (
    <>
      {/* TODO loadingの汎用コンポーネントができたら共通化する */}
      {isLoading && (
        <div className={styles.loadingContainer}>
          <div className={styles.loadingSpinner} />
          <div className={styles.loadingSpinnerText}>ただいま処理中です。暫くお待ちください。</div>
        </div>
      )}
      <div className={styles.sectionOuter}>
        <SectionTitle text="勤続証明書類" />
        <div className={styles.container}>
          <InformationText
            text="個人情報保護のため勤続証明書類はバックアップされません。"
            icon={<Icon name="information" />}
          />
          <div className={styles.fileUploaderContainer}>
            <SectionWithTitleAndLabel
              isRequired
              title="勤続証明書類"
              text={
                <>
                  会社名、氏名、入社から1年経過したと分かる日付の、3点が記載されている資料（例：給与明細、シフト表、サロンボード、在籍証明書）
                  <br />
                  ファイルサイズ：10MB まで 3枚まで添付可能
                </>
              }
              containerRef={imagesRef}
            >
              <ImageFileUploader
                handleClickSelectButton={handleClickSelectButton}
                handleChangeFile={handleChangeFile}
                fileInputRef={fileInputRef}
                previewUrlArray={previewUrlArray}
                handleClickDeleteButton={handleClickDeleteButton}
              />
            </SectionWithTitleAndLabel>
          </div>
          {errorText && <ErrorInformationText text={errorText} />}
        </div>
      </div>
    </>
  );
};
