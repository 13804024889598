import { FC, lazy, Suspense, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  TextField,
  Pulldown,
  ValidationErrorMessage,
  RequiredLabel,
} from "@legacy_user_frontend/components/ui_parts";
import { BoxWithShadow } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/box_with_shadow";
import { DefinitionList } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/definition_list";
import { DeleteTextLinkButton } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/delete_text_button";
import { SchoolLogsCassetteProps } from "@legacy_user_frontend/components/pages/mypage/profiles/school_logs_edit/src/school_logs_edit_props";
import { TextButton } from "@root/shared/components/TextButton";
import {
  StyledCassetteHeadingOuter,
  StyledCassetteHeading,
  StyledCassetteContent,
  StyledErrorMessageOuter,
  StyledCheckboxOuter,
  StyledCheckboxText,
  StyledRemoveModalOuter,
  StyledRemoveModalHeading,
  StyledRemoveButtonOuter,
  StyledModalCloseButtonOuter,
  StyledDateSelectOuter,
} from "@legacy_user_frontend/components/pages/mypage/profiles/school_logs_edit/src/styled_elements";
import styled from "styled-components";
import { Checkbox } from "@root/shared/components/Checkbox";
import { Button } from "@root/shared/components/Button";

const Modal = lazy(() => import("@root/shared/components/Modal"));

export const SchoolLogsCassette: FC<SchoolLogsCassetteProps> = ({
  cassetteIndex,
  graduatedYearList,
  graduatedMonthList,
  remove,
}) => {
  const { formState } = useFormContext();
  const formName = "form_user_frontend_user_schools_update[user_schools_attributes]";
  const errors = formState.errors.form_user_frontend_user_schools_update?.user_schools_attributes;
  const cassetteErrors = errors && errors.length ? errors[cassetteIndex] : null;

  // 削除確認モーダルの表示制御
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOnClick = () => {
    setIsModalOpen(true);
  };
  const handleOnClose = () => {
    setIsModalOpen(false);
  };

  return (
    <BoxWithShadow id={`cassette-${cassetteIndex}`}>
      <StyledCassetteHeadingOuter>
        <StyledCassetteHeading>学歴{cassetteIndex + 1}</StyledCassetteHeading>
        <DeleteTextLinkButton type="button" onClick={handleOnClick} size="small">
          削除する
        </DeleteTextLinkButton>
      </StyledCassetteHeadingOuter>
      <StyledCassetteContent>
        <DefinitionList>
          <dt>
            学校名
            <span className="required-label-outer">
              <RequiredLabel />
            </span>
          </dt>
          <dd>
            <TextField
              name={`${formName}[${cassetteIndex}][school_name]`}
              placeholder="例）リジョブ専門学校"
              isError={!!(cassetteErrors && cassetteErrors.school_name?.message)}
            />
            {cassetteErrors && cassetteErrors.school_name?.message && (
              <StyledErrorMessageOuter>
                <ValidationErrorMessage message={cassetteErrors.school_name.message} />
              </StyledErrorMessageOuter>
            )}
          </dd>
          <dt>学部・学科・コース</dt>
          <dd>
            <TextField
              name={`${formName}[${cassetteIndex}][course]`}
              placeholder="例）リジョブコース"
            />
          </dd>
          <dt>
            卒業年月
            <span className="required-label-outer">
              <RequiredLabel />
            </span>
          </dt>
          <dd>
            <StyledDateSelectOuter>
              <Pulldown
                name={`${formName}[${cassetteIndex}][graduated_year]`}
                placeholder="年"
                list={graduatedYearList}
                isError={!!(cassetteErrors && cassetteErrors.graduated_year?.message)}
              />
              <Pulldown
                name={`${formName}[${cassetteIndex}][graduated_month]`}
                placeholder="月"
                list={graduatedMonthList}
                isError={!!(cassetteErrors && cassetteErrors.graduated_month?.message)}
              />
            </StyledDateSelectOuter>
            {cassetteErrors && (
              <StyledErrorMessageOuter>
                {cassetteErrors.graduated_year?.message && (
                  <ValidationErrorMessage message={cassetteErrors.graduated_year.message} />
                )}
                {cassetteErrors.graduated_month?.message && (
                  <ValidationErrorMessage message={cassetteErrors.graduated_month.message} />
                )}
              </StyledErrorMessageOuter>
            )}
          </dd>
        </DefinitionList>
        <StyledCheckboxOuter>
          <Checkbox
            value="1"
            name={`${formName}[${cassetteIndex}][will_graduate_flag]`}
            id={`form_user_frontend_user_schools_update_user_schools_attributes_${cassetteIndex}_will_graduate_flag`}
          />
          <StyledCheckboxText
            htmlFor={`form_user_frontend_user_schools_update_user_schools_attributes_${cassetteIndex}_will_graduate_flag`}
          >
            卒業予定
          </StyledCheckboxText>
        </StyledCheckboxOuter>
      </StyledCassetteContent>
      <Suspense fallback={null}>
        {isModalOpen && (
          <Modal
            isShow={isModalOpen}
            onClose={handleOnClose}
            spAppearanceType="center"
            pcWidthType="small"
            headerTitleStyle="white"
          >
            <StyledRemoveModalOuter>
              <StyledRemoveModalHeading>
                学歴{cassetteIndex + 1}を本当に
                <br />
                削除してよろしいですか？
              </StyledRemoveModalHeading>
              <StyledRemoveButtonOuter>
                <ButtonWrapper>
                  <Button theme="primary" onClick={() => remove(cassetteIndex)}>
                    削除する
                  </Button>
                </ButtonWrapper>
              </StyledRemoveButtonOuter>
              <StyledModalCloseButtonOuter>
                <TextButton onClick={handleOnClose} size="large" isBold>
                  戻る
                </TextButton>
              </StyledModalCloseButtonOuter>
            </StyledRemoveModalOuter>
          </Modal>
        )}
      </Suspense>
    </BoxWithShadow>
  );
};

const ButtonWrapper = styled.div`
  height: 48px;
`;
