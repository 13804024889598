import { FC } from "react";
import Modal from "@root/shared/components/Modal";
import { Link } from "@root/shared/components/Link";
import { Button } from "@root/shared/components/Button";
import { USER_REWARD_NAME } from "@root/utils/constants";
import * as styles from "./styles.css";

type Props = {
  userContactUrl: string;
  showModal: boolean;
  closeHandler: () => void;
};

export const UserRewardPromotionModal: FC<Props> = ({
  userContactUrl,
  showModal,
  closeHandler,
}) => {
  return (
    <Modal isShow={showModal} onClose={closeHandler} spAppearanceType="center">
      <div className={styles.modalBody}>
        <p
          className={styles.modalText}
        >{`${USER_REWARD_NAME}申請について質問やお困り事はありませんか？`}</p>
        <Link
          theme="primary"
          className={styles.modalButton}
          href={userContactUrl}
          data-ga-action="user_rewards_exit_popup"
        >
          リジョブに問い合わせる
        </Link>
        <Button className={styles.modalButtonClose} onClick={closeHandler}>
          今はしない
        </Button>
      </div>
    </Modal>
  );
};
